/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/
var path = location.pathname.substring(1);
var $navLinkEl = $('nav a[href$="' + path + '"]');

if (path) {
    $navLinkEl.addClass('selected');
    $navLinkEl.closest('.navicon').children('a').addClass('selected');
    $('.sidebar li a[href$="' + path + '"]').parent().addClass('selected');
};

if(path.split('/')[0] === 'jitasa_nonprofit_blog') {
    $('nav a[href$="/jitasa_nonprofit_blog/"]').addClass('selected');
}

if(path.split('/')[1] === 'nonprofit-free-downloads') {
    $('nav a[href$="/nonprofit-free-downloads/"]').addClass('selected');
    $('nav a[href$="/nonprofit-resources/"]').addClass('selected');
}

if(path.split('/')[1] === 'free-courses') {
    $('nav a[href$="/free-courses/"]').addClass('selected');
    $('nav a[href$="/nonprofit-resources/"]').addClass('selected');
}


/* ==================================================
Accordion:

Simple toggleable accordion
===================================================*/

/* ==================================================
Dropdown List:

Toggles animated display of the next element with
the class of 'list' relative to the click trigger.
Recommend using ul structure. Default animation is
hinge in and out from left.
===================================================*/

$('.dropdown-list').on('click', '.trigger', function(e) {
    e.preventDefault();
    $(this).toggleClass('open');
    var $toggleNext = new Foundation.Toggler($(this).next('.list'), {
        animate: 'animate-in animate-out'
    });
    var trigger = $(this).next('.list');
    $(trigger).foundation('toggle');
});


/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function(e) {
    e.preventDefault();
    var triggerId = $(this).data('toggle-trigger');
    var toggleItem = $('#'+ triggerId);

    $(this).toggleClass('open');

    var $toggleElement = new Foundation.Toggler(toggleItem, {
        animate: 'animate-in animate-out'
    });

    toggleItem.foundation('toggle');
});


/* ==================================================
Pricing table toggle:

Toggles animated display of optional services on
pricing tables and switches out class on hidden
cost value.
===================================================*/
(function(){
    var optionShown = true;
    var $pricingTable = $('.pricing-table');

    $('.pricing-table-toggle [data-service-type]').on('click',showServiceType);
    function showServiceType() {
        var buttonClicked = this;
        var serviceType = this.dataset.serviceType;
        var $options = $pricingTable.find('.js-options');
        var $cost = $pricingTable.find('.price');

        $('.button-group button').each(function(){
            $(this).removeClass('active');
        });
        buttonClicked.classList.add('active');

        if(serviceType === "show") {
            if(!optionShown) {
                optionShown = true;

                $cost.each(function() {
                    var $el = $(this);
                    $el.find('.js-initial-cost').addClass('hide');
                    $el.find('.js-options-cost').removeClass('hide');

                });
                $options.each(function() {
                    var $el = $(this);
                    var $elHeight = $el.find('.option-inner').outerHeight(true);
                    $el.css('max-height',$elHeight);
                    $el.addClass('open');
                });
            }
            return;
        } else {
            optionShown = false;

            $cost.each(function() {
                var $el = $(this);
                $el.find('.js-initial-cost').removeClass('hide');
                $el.find('.js-options-cost').addClass('hide');
            });
            $options.each(function() {
                var $el = $(this);
                var $elHeight = $el.find('.option-inner').outerHeight(true);
                $el.css('max-height', 0);
                $el.removeClass('open');

            });
        }

    }
})();

/* ==================================================
Header resize on scroll:

Triggers animated resize of header height on
scroll
===================================================*/
var $header = $('.page-header');
var $headerInner = $('.page-header .header-inner');
var headerShrink = "smaller";
var $logo = $('.page-header .main-logo');
var $flame = $('.page-header .flame-logo');
var $alertBar = $('.alert-bar');
var scrolledPage = false;

var debounceHeaderScroll = debounce(headerScroll, 50);
var debouncePageResize = debounce(changeAlertBarSpacing, 50);

window.addEventListener('scroll', debounceHeaderScroll);
window.addEventListener('resize', debouncePageResize);

function headerScroll() {
    var pageHeader = document.querySelector('.page-header');
    var topOfHeader = pageHeader.offsetHeight;

    if($alertBar.length && !scrolledPage) {
        changeAlertBarSpacing();
    }

    if(window.scrollY > 0 && !scrolledPage) {
        scrolledPage = true;
    }

    if(topOfHeader <= window.scrollY) {
        document.body.classList.add('fixed-nav');
        flameLogo();
    } else if(scrolledPage) {
        document.body.classList.remove('fixed-nav');
        fullLogo();
    }
}

function changeAlertBarSpacing() {
    $('body').css("padding-top", $alertBar.outerHeight() + $headerInner.outerHeight() + 'px')
    // $('.page-header').css("top", $alertBar.outerHeight() + 'px');
}

function flameLogo() {
    if(!($flame.hasClass('isVisible'))) {
        Foundation.Motion.animateOut($logo, 'fade-out fast', function() {
          $flame.addClass('fade-scale-animation').css('display', 'block');
        });
        $flame.addClass('isVisible');
        $logo.removeClass('isVisible');
    }
}

function fullLogo() {
    if(!($logo.hasClass('isVisible'))) {
        $header.removeClass(headerShrink);
        $flame.removeClass('fade-scale-animation').css('display', 'none');
        Foundation.Motion.animateIn($logo, 'logo-slide-in');
        $flame.removeClass('isVisible');
        $logo.addClass('isVisible');
    }
}


/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
$('.nav-toggle').click(function(e) {
    e.preventDefault();
    var $mainNav = $('.page-header .main-nav-small');
    var $navToggle = $(this);
    var $isScrolled = $('body').hasClass('fixed-nav');

    $navToggle.toggleClass('active');
    $mainNav.toggleClass('open');
    $navToggle.children('.hamburger').toggleClass('active');

    if($mainNav.hasClass('open')) {
        Foundation.Motion.animateIn($mainNav, 'slide-in-right fast');
        flameLogo();
    } else {
        Foundation.Motion.animateOut($mainNav, 'slide-out-right fast');
        if(!$isScrolled) {
            fullLogo();
        }
    }
});


/* ==================================================
Nav dropdown toggle:

Toggles animated display of child UL for nav items
===================================================*/
$('.main-nav-small').on('click', '.sub-nav-arrow', function(e) {
    e.preventDefault();

    var $dropdownParent = $(this).closest('.has-dropdown');
    var subnavContainer = $dropdownParent.find('ul');
    var subnavHeight = subnavContainer[0].offsetHeight + subnavContainer[0].scrollHeight;

    $(this).toggleClass('open');
    $dropdownParent.toggleClass('open');
});


//make init function with the functions to invoke in it

$(function() {
    $('.inline-modal-link').magnificPopup({
      type: 'inline',
      removalDelay: 300,
      mainClass: 'mfp-fade'
    });
    $('.video-modal-link').magnificPopup({
      type: 'iframe',
      removalDelay: 300,
      mainClass: 'mfp-fade'
    });
    headerScroll();
});


/* ==================================================
Debounce function:

Rate limits multiple event triggers
===================================================*/
function debounce(fn, wait) {
  var timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      fn.apply(this, arguments)
    }, (wait || 1));
  }
}


/* ==================================================
Testimonial slider:

Testimonial slider
===================================================*/
$(function() {
    $('.testimonial-slider').slick({
        arrows: false,
        dots: true,
        dotsClass: 'slider-nav',
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnFocus: true,
        pauseOnHover: true,
        pauseOnDotsHover: true
    });
});

/* ==================================================
Tax filings calculation:

Multiplies the start value of the tax filings and
adds 2 each day to "auto" incrase the number.
Updates the text on the tax span element after
calculation
===================================================*/
var taxFilingCalcStartDate = new Date('April 1, 2019');
var taxFilingStartAmount = 1215;
var taxFilingAmountText = document.querySelector('#tax-filings-amount');
var taxFilingAmountCalc = taxFilingStartAmount + (Math.round((Date.now() - taxFilingCalcStartDate.getTime()) / (1000*60*60*24))*2);
if(taxFilingAmountText) {
    taxFilingAmountText.innerText = taxFilingAmountCalc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
}

// Tax counter
const numberCounters = document.querySelectorAll('.number-counter-total');
const speed = 200;

const numberCountersObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    const intersecting = entry.isIntersecting;
    if(intersecting) {
        animateNumberCounter(entry.target)
    }
  })
}, {
    threshold: 1,
    rootMargin: "0px 0px -10px",
})

numberCounters.forEach( counter => {
    numberCountersObserver.observe(counter)
});

function animateNumberCounter(counter) {
    let maxNum = counter.dataset.maxnumber;
    const animate = () => {
    const value = +counter.dataset.maxnumber;
    const data = +counter.innerText;
    const time = value / speed;
    if(data < value) {
        counter.innerText = Math.ceil(data + time);
        let threshold = Math.ceil(data + time) / maxNum;
        if(threshold > .95) {
            setTimeout(animate, 100);
        } else {
            setTimeout(animate, 1);
        }
    } else {
        counter.innerText = `${value.toLocaleString()}+`;
        numberCountersObserver.unobserve(counter);
    }
   }
   animate();
}



/* ==================================================
Background image rotator:

Rotates background images behind static text
content
===================================================*/
var imgSlides = $('.slider-bg-img');

if(imgSlides) {
    function changeSlide() {
        imgSlides.each(function(index) {
            var slide = $(this);
            var slideIndex = index + 1;

            if (slide.hasClass('current')) {
                if (slideIndex >= imgSlides.length) {

                    $('.img-0').addClass('current').fadeIn('slow', function() {
                        slide.removeClass('current').fadeOut('fast');
                    });
                    return false;
                }

                $('.img-' + slideIndex).addClass('current').fadeIn('slow', function() {
                    slide.removeClass('current').fadeOut('fast');
                });
                return false;
            }
        });
    }

    setInterval(changeSlide, 8000);
}

/* ==================================================
Reveal call:

Uses select list to display who to call after
selecting gross revenue
===================================================*/
var currentScheduleCallPerson = '';
var scheduleCallDisplayed = false;
var scheduleCallSalesteamMap = {
    corey: "corey-johnson",
    sophie: "sophie-hanigan",
    christian: "christian",
    steve: "steve-doud",
};

$('#schedule-call-reveal').change(function() {
    let salesperson = $(this).val().split('/');
    let salesValue = $(this).find("option:selected").text();
    var selectedScheduleCallPerson = $(this).val();

    if($(this).val() === '') {
        return;
    }

    if(salesperson.length > 1) {
        selectedScheduleCallPerson = scheduleCallSalesteamMap[salesperson[Math.floor(Math.random() * salesperson.length)]];
    } else {
        if(currentScheduleCallPerson === selectedScheduleCallPerson) {
            return;
        }
    }

    currentScheduleCallPerson = selectedScheduleCallPerson;
    displayScheduleCallPerson(selectedScheduleCallPerson, salesValue);
});

function displayScheduleCallPerson(person, value) {
    if(scheduleCallDisplayed) {
        $('.schedule-call-result .open').slideUp(400, 'swing', function() {
            $(this).removeClass('open');
            $('#' + person).slideDown().addClass('open');
            document.querySelector(`#${person} > .button`).dataset.gtmSalesValue = value;
        });
    } else {
        $('#' + person).slideDown().addClass('open');
        scheduleCallDisplayed = true;
        document.querySelector(`#${person} > .button`).dataset.gtmSalesValue = value;
    }
    window.dataLayer.push({
        'event': 'cta_select_revenue',
        'sales_contact': person,
        'revenue_amount': value
    })
}


/* ==================================================
Blog email subscription:

Subscribe to email list from blog templates
===================================================*/
$('#blog-email-subscribe').submit(function(e) {
    e.preventDefault();
    $(this).children('.button').prop('disabled', true).addClass('disabled');
    var blogSubscribeFormData = $(this).serialize();

    $.ajax({
      url: "https://jitasa-pipedrive-forms.herokuapp.com/subscribe",
      type: "POST",
      data: blogSubscribeFormData,
      contentType: "application/x-www-form-urlencoded; charset=UTF-8" // optional
    }).done(function(res) {
        // console.log("Submitted form")
        window.location = "/thanks/blog-email-subscribe/";
    });
});

/* ==================================================
Free downloads email subscription:

Subscribe to email list from free download page
===================================================*/
$('.free-downloads-email-subscribe').submit(function(e) {
    e.preventDefault();
    $('.free-downloads-email-subscribe .button').prop('disabled', true).addClass('disabled');
    var freeDownloadsSubscribeFormData = $(this).serialize();

    $.ajax({
      url: "https://jitasa-pipedrive-forms.herokuapp.com/subscribe-free-downloads",
      type: "POST",
      data: freeDownloadsSubscribeFormData,
      contentType: "application/x-www-form-urlencoded; charset=UTF-8" // optional
    }).done(function(res) {
        localStorage.setItem('freeDownloads', "accessed");
        document.querySelectorAll('.simple-email-callout').forEach(callout => callout.remove());
        document.querySelectorAll('.resource-download-approve').forEach(resource => resource.classList.remove('hide'));
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'free_download_email_submit'
        });
    });
});

/* ==================================================
Email subscription:

Subscribe to email list from thanks page
===================================================*/
$('.simple-email-subscribe').submit(function(e) {
    e.preventDefault();
    $('.simple-email-subscribe .button').prop('disabled', true).addClass('disabled');
    let emailSubscribeFormData = $(this).serialize();

    $.ajax({
      url: "https://jitasa-pipedrive-forms.herokuapp.com/subscribe",
      type: "POST",
      data: emailSubscribeFormData,
      contentType: "application/x-www-form-urlencoded; charset=UTF-8" // optional
    }).done(function(res) {
        document.querySelectorAll('.simple-email-subscribe').forEach(callout => callout.remove());
        document.querySelectorAll('.simple-email-subscribe-message').forEach(message => message.classList.remove('hide'));
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'email_signup'
        });
    });
});


/* ==================================================
Get UTM and referrer data:

Get UTM and referrer data from URL if present, store in local
storage with an added date. Remove from local storage
after 3 days or form submit. If new UTM data is
present in URL before expiration, add the new data
and change the start time to now.
===================================================*/
// Max age is 3 days, in milliseconds
let localMaxAge = 3 * 24 * 60 * 60 * 1000;
let utmAvailable = false;
let referrerInfo = document.referrer;

let referrerParamsMap = {
    utm_campaign_first_touch: 'ft_campaign',
    utm_campaign_first_device: 'ft_device',
    utm_campaign_first_keyword: 'ft_keyword',
    utm_campaign_first_medium: 'ft_medium',
    utm_campaign_first_source: 'ft_source',
    utm_campaign_last_touch: 'last_campaign',
    utm_campaign_last_device: 'last_device',
    utm_campaign_last_keyword: 'last_keyword',
    utm_campaign_last_medium: 'last_medium',
    utm_campaign_last_source: 'last_source',
};

let referrerParams = {
    ft_campaign: '',
    ft_device: '',
    ft_keyword: '',
    ft_medium: '',
    ft_source: '',
    lt_campaign: '',
    lt_device: '',
    lt_keyword: '',
    lt_medium: '',
    lt_source: '',
};

let referrerList = [
    {
        name: 'google',
        paramName: 'google',
        paramType: 'organic'
    },
    {
        name: 'bing',
        paramName: 'bing',
        paramType: 'organic'
    },
    {
        name: 'duckduck',
        paramName: 'duckduckgo',
        paramType: 'organic'
    },
    {
        name: 'facebook',
        paramName: 'facebook',
        paramType: 'social'
    },
    {
        name: 'instagram',
        paramName: 'instagram',
        paramType: 'social'
    },
    {
        name: 'twitter',
        paramName: 'twitter',
        paramType: 'social'
    },
    {
        name: 'snapchat',
        paramName: 'snapchat',
        paramType: 'social'
    },
    {
        name: 'tiktok',
        paramName: 'tiktok',
        paramType: 'social'
    }
];

function getQueryString() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // console.log(queryString);
    return urlParams;
}

function getUTMParams(urlParams) {
    return {
        source: urlParams.get('utm_source'),
        medium: urlParams.get('utm_medium'),
        campaign: urlParams.get('utm_campaign'),
        content: urlParams.get('utm_content'),
        device: urlParams.get('utm_device'),
        keyword: urlParams.get('utm_keyword')
    }
}

function setReferrer(referrer) {
    let referrerData = getLocalStorageParams('referrer_data');
    let referrerDataExpiry = getLocalStorageParams('referrer_data', true);
    let utmData = getLocalStorageParams('utm_data');
    let utmParams = { ...utmData };
    let referrerService = referrerList.find(item => referrer.includes(item.name));
    let referrerSource = {
        source: '',
        medium: ''
    };
    // If referrer expiry doesn't exist set maxAge to 3 days
    let maxAge = referrerDataExpiry || localMaxAge;

    // console.log("Initial referrer data:", referrerData, new Date(referrerDataExpiry))
    // If no referrer data in localstorage, scaffold out
    if(!referrerData) {
        if(!utmData) {
            utmParams.source = '';
            utmParams.medium = '';
            utmParams.campaign = '';
            utmParams.content = '';
            utmParams.device = '';
            utmParams.keyword = '';
        }
        // console.log("UTM params", utmParams)
        referrerData = {
            utm_campaign_first_touch: utmParams.campaign,
            utm_device_first_touch: utmParams.device,
            utm_keyword_first_touch: utmParams.keyword,
            utm_medium_first_touch: utmParams.medium,
            utm_source_first_touch: utmParams.source,
            utm_campaign_last_touch: utmParams.campaign,
            utm_device_last_touch: utmParams.device,
            utm_keyword_last_touch: utmParams.keyword,
            utm_medium_last_touch: utmParams.medium,
            utm_source_last_touch: utmParams.source
        };
    }

    // Get current referrer to be applied to first or last touch
    if(referrerService) {
        referrerSource.source = referrerService.paramName;
        referrerSource.medium = referrerService.paramType;
    } else if(referrer){
        // If service not in map, manually set as referral with document.referrer
        referrerSource.source = referrer;
        referrerSource.medium = "referral";
    } else {
        referrerSource.source = "not set";
        referrerSource.medium = "direct";
    };

    if(!referrerData.utm_source_first_touch && !referrerData.utm_medium_first_touch) {
        // console.log("No first touch set")
        referrerData.utm_source_first_touch = utmParams.source || referrerSource.source;
        referrerData.utm_medium_first_touch = utmParams.medium || referrerSource.medium;
        maxAge = localMaxAge;
    }

    referrerData.utm_source_last_touch = utmAvailable ? utmParams.source : referrerSource.source;
    referrerData.utm_medium_last_touch = utmAvailable ? utmParams.medium : referrerSource.medium;
    referrerData.utm_keyword_last_touch = utmAvailable ? utmParams.keyword : '';
    referrerData.utm_campaign_last_touch = utmAvailable ? utmParams.campaign : '';
    referrerData.utm_device_last_touch = utmAvailable ? utmParams.device : '';

    // If expiry already exists
    if(referrerDataExpiry) {
        setLocalStorageParams('referrer_data', referrerData, maxAge, true);
    } else {
        setLocalStorageParams('referrer_data', referrerData, maxAge);
    }
    // console.log("Referrer source: ", referrerSource)
    // console.log("UTM data: ", utmData)
    // console.log("Max age:", new Date(maxAge))
    // console.log("Referrer data: ", referrerData)
    return referrerData;
}

function setLocalStorageParams(key, value, expiry, skipAdd) {
    // Take today's date and add passed expiry
    let futureDate = Date.now() + expiry;
    let maxAge = Date.now() + localMaxAge;
    let result = {
        data: value,
        expiry: skipAdd ? expiry : futureDate
    };
    if(result.expiry > Date.now() + maxAge) {
        result.expiry = Date.now() + maxAge;
    }
    // console.log("Expiry age:", new Date(result.expiry), "Max Expiry age:", new Date(maxAge))
    window.localStorage.setItem(key, JSON.stringify(result));
}

function getLocalStorageParams(key, expiry) {
    let result = JSON.parse(window.localStorage.getItem(key));
    if(result && expiry) {
        return result.expiry;
    }
    if(result) {
        // If item is expired, remove it
        if(result.expiry <= Date.now()){
            window.localStorage.removeItem(key);
            return null;
        }
        return result.data;
    }
}

function deleteLocalStorageParams(key) {
    window.localStorage.removeItem(key);
    return key;
}

function checkLocalStorageExpiry(items) {
    items.forEach(item => getLocalStorageParams(item))
}

checkLocalStorageExpiry(['utm_data', 'referrerData'])

// Check for querystrings (potential UTM params)
let queryStrings = getQueryString();

if(queryStrings.size) {
    // Get UTM params parsed out of querystring
    let utmParams = getUTMParams(queryStrings);
    // console.log(utmParams)
    // See if minimum required utm params are present
    if(utmParams.source && utmParams.medium) {
        // UTM params exist
        utmAvailable = true;
        // Get existing referrer data, if any
        let referrerData = getLocalStorageParams('referrer_data');
        setLocalStorageParams('utm_data', utmParams, localMaxAge);
        // Set referrer data with expiry matched to UTM
        setLocalStorageParams('referrer_data', referrerData, localMaxAge);
    }
}

if(!referrerInfo.includes('jitasa')) {
    setReferrer(referrerInfo);
    // console.log("Referrer expiry:", new Date(getLocalStorageParams('referrer_data', true)))
}